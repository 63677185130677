<template>
  <div>
    <div>
      <v-row>
        <v-col
          cols="4"
          lg="2"
        >
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dates"
                label="Change Month"
                :prepend-icon="icons.mdiCalendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              type="month"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(dates)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col
        cols="12"
        class="text-center"
      >
        <span>Nursing</span>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <statistics-card-with-images
          :avatar="averagePopulation.avatar"
          :avatar-width="averagePopulation.avatarWidth"
          :change="averagePopulation.change"
          :chip-color="averagePopulation.chipColor"
          :chip-text="dates"
          :statistics="averagePopulation.statistics"
          :stat-title="averagePopulation.statTitle"
        ></statistics-card-with-images>
      </v-col>
      <v-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <statistics-card-with-images
          :avatar="detoxPopulation.avatar"
          :avatar-width="detoxPopulation.avatarWidth"
          :change="detoxPopulation.change"
          :chip-color="detoxPopulation.chipColor"
          :chip-text="detoxPopulation.chipText"
          :statistics="detoxPopulation.statistics"
          :stat-title="detoxPopulation.statTitle"
        ></statistics-card-with-images>
      </v-col>
      <v-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <statistics-card-with-images
          :avatar="suicideWatch.avatar"
          :avatar-width="suicideWatch.avatarWidth"
          :change="suicideWatch.change"
          :chip-color="suicideWatch.chipColor"
          :chip-text="suicideWatch.chipText"
          :statistics="suicideWatch.statistics"
          :stat-title="suicideWatch.statTitle"
        ></statistics-card-with-images>
      </v-col>
      <v-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <statistics-card-with-images
          :avatar="sickCalls.avatar"
          :avatar-width="sickCalls.avatarWidth"
          :change="sickCalls.change"
          :chip-color="sickCalls.chipColor"
          :chip-text="sickCalls.chipText"
          :statistics="sickCalls.statistics"
          :stat-title="sickCalls.statTitle"
        ></statistics-card-with-images>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        class="text-center"
      >
        <span>Medications</span>
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row class="match-height">
      <v-col
        lg="3"
        md="4"
        sm="6"
      >
        <statistics-card-profit-line-chart
          :stat-title="medErrorChart.statTitle"
          :statistics="medErrorChart.statistics"
          :chart-series="medErrorChart.series"
          :chart-color="$vuetify.theme.currentTheme.success"
        ></statistics-card-profit-line-chart>
      </v-col>
      <v-col
        lg="3"
        md="4"
        sm="6"
      >
        <statistics-card-vertical
          :change="totalMedVerifications.change"
          :color="totalMedVerifications.color"
          :icon="totalMedVerifications.icon"
          :statistics="totalMedVerifications.statistics"
          :stat-title="totalMedVerifications.statTitle"
          :subtitle="totalMedVerifications.subtitle"
        ></statistics-card-vertical>
      </v-col>
      <v-col
        lg="3"
        md="4"
        sm="6"
      >
        <statistics-card-radial-bar-chart
          :stat-title="medicationRadialChart.statTitle"
          :statistics="medicationRadialChart.statistics"
          :chart-series="medicationRadialChart.series"
          :chart-color="$vuetify.theme.currentTheme.info"
        ></statistics-card-radial-bar-chart>
      </v-col>
      <v-col
        lg="3"
        md="4"
        sm="6"
      >
        <statistics-card-radial-bar-chart
          :stat-title="psychotropicsRadialChart.statTitle"
          :statistics="psychotropicsRadialChart.statistics"
          :chart-series="psychotropicsRadialChart.series"
          :chart-color="$vuetify.theme.currentTheme.warning"
        ></statistics-card-radial-bar-chart>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        class="text-center"
      >
        <span>Mental Health</span>
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        lg="3"
        md="4"
        sm="12"
      >
        <statistics-card-with-images
          :avatar="suicideWatch.avatar"
          :avatar-width="suicideWatch.avatarWidth"
          :change="suicideWatch.change"
          :chip-color="suicideWatch.chipColor"
          :chip-text="suicideWatch.chipText"
          :statistics="suicideWatch.statistics"
          :stat-title="suicideWatch.statTitle"
        ></statistics-card-with-images>
      </v-col>

      <v-col
        lg="6"
        md="12"
        sm="12"
      >
        <v-card>
          <v-card-title class="align-start">
            <span class="font-weight-semibold">Mental Health Overview</span>
          </v-card-title>

          <v-card-subtitle class="mb-7">
            <span class="font-weight-semibold text--primary me-1">{{ dates }}</span>
          </v-card-subtitle>

          <v-card-text>
            <v-row>
              <v-col
                v-for="data in alertsData"
                :key="data.title"
                class="d-flex align-center"
              >
                <v-avatar
                  size="44"
                  :color="resolveStatisticsIconVariation (data.title).color"
                  rounded
                  class="elevation-1"
                >
                  <v-icon
                    dark
                    color="white"
                    size="30"
                  >
                    {{ resolveStatisticsIconVariation (data.title).icon }}
                  </v-icon>
                </v-avatar>

                <div class="ms-3">
                  <p class="text-xs mb-0">
                    {{ data.title }}
                  </p>
                  <h3 class="text-xl font-weight-bold">
                    {{ data.total }}
                  </h3>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        class="text-center"
      >
        <span>Chronic Clinic</span>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-col
      col="12"
    >
      <v-card>
        <v-card-title class="align-start">
          <span class="font-weight-semibold">Chronic Clinic Overview</span>
        </v-card-title>

        <v-card-subtitle class="mb-7">
          <span class="font-weight-semibold text--primary me-1">{{ dates }}</span>
        </v-card-subtitle>

        <v-card-text>
          <v-row>
            <v-col
              v-for="data in chronicClinicData"
              :key="data.title"
              class="d-flex align-center"
            >
              <v-avatar
                :color="resolveStatisticsIconVariation (data.title).color"
                rounded
                class="elevation-1"
              >
                <v-icon
                  dark
                  color="white"
                >
                  {{ resolveStatisticsIconVariation (data.title).icon }}
                </v-icon>
              </v-avatar>

              <div class="ms-3">
                <p class="text-xs mb-0">
                  {{ data.title }}
                </p>
                <h3 class="text-xl font-weight-bold">
                  {{ data.total }}
                </h3>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-row>
      <v-col
        cols="12"
        class="text-center"
      >
        <span>Radiology</span>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        lg="3"
        md="4"
        sm="6"
      >
        <statistics-card-with-images
          :avatar="averagePopulation.avatar"
          :avatar-width="averagePopulation.avatarWidth"
          :change="averagePopulation.change"
          :chip-color="averagePopulation.chipColor"
          :chip-text="averagePopulation.chipText"
          :statistics="averagePopulation.statistics"
          :stat-title="averagePopulation.statTitle"
        ></statistics-card-with-images>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mdiPoll, mdiCalendar } from '@mdi/js'

// Cards
import StatisticsCardVertical from '@/@core/components/statistics-card/StatisticsCardVertical.vue'
import StatisticsCardProfitLineChart from '@core/components/statistics-card/StatisticsCardProfitLineChart.vue'
import StatisticsCardRadialBarChart from '@core/components/statistics-card/StatisticsCardRadialBarChart.vue'
import StatisticsCardWithImages from '@core/components/statistics-card/StatisticsCardWithImages.vue'

export default {
  components: {
    StatisticsCardWithImages,
    StatisticsCardProfitLineChart,
    StatisticsCardVertical,
    StatisticsCardRadialBarChart,
  },

  setup() {
    const averagePopulation = {
      statTitle: 'Average Daily Population',
      statistics: '421 Patients',
      change: '+10%',
      chipText: 'This Month',
      chipColor: 'success',
      avatar: require('@/assets/images/avatars/Population.png'),
      avatarWidth: '135',
    }
    const detoxPopulation = {
      statTitle: 'Detox Population',
      statistics: '21 Patients',
      change: '-10%',
      chipText: 'This Month',
      chipColor: 'success',
      avatar: require('@/assets/images/avatars/Population.png'),
      avatarWidth: '135',
    }
    const suicideWatch = {
      statTitle: 'Suicide Watch',
      statistics: '12 Patients',
      change: '-10%',
      chipText: 'This Month',
      chipColor: 'success',
      avatar: require('@/assets/images/alerts/suicideWatch.png'),
      avatarWidth: '135',
    }
    const sickCalls = {
      statTitle: 'Nurse Sick Calls',
      statistics: '40 Visits',
      change: '-20%',
      chipText: 'This Month',
      chipColor: 'neutral',
      avatar: require('@/assets/images/avatars/9.png'),
      avatarWidth: '111',
    }
    const medErrorChart = {
      statTitle: 'Total Medication Errors',
      statistics: '200 this month',
      series: [
        {
          data: [100, 212, 530, 251, 200],
        },
      ],
    }
    const totalMedVerifications = {
      statTitle: 'Medication Verifications',
      icon: mdiPoll,
      color: 'secondary',
      subtitle: 'This Month',
      statistics: '200',
      change: '+22%',
    }
    const medicationRadialChart = {
      statTitle: 'Patients on Medications',
      statistics: '700 Total Patients',
      series: [82],
    }
    const psychotropicsRadialChart = {
      statTitle: 'Patients on Psychiatric Meds',
      statistics: '700 Total Patients',
      series: [25],
    }

    const alertsData = [
      {
        title: 'Self Harm with Hospital',
        total: '12',
      },
      {
        title: 'Self Harm without Hospital',
        total: '105',
      },
      {
        title: 'Single Celled',
        total: '18',
      },
      {
        title: 'From Seg to General Pop',
        total: '1',
      },
    ]

    const chronicClinicData = [
      {
        title: 'Diabetes',
        total: '12',
      },
      {
        title: 'Hypertension',
        total: '105',
      },
      {
        title: 'Hyperlipidemia',
        total: '18',
      },
      {
        title: 'Seizures',
        total: '1',
      },
      {
        title: 'HIV',
        total: '12',
      },
      {
        title: 'Hypothyroidism',
        total: '105',
      },
      {
        title: 'Endocrine Disorder',
        total: '18',
      },
      {
        title: 'Hyperthyroidism',
        total: '1',
      },
      {
        title: 'Pregnancy',
        total: '18',
      },
      {
        title: 'Major Mental Illness',
        total: '0',
      },
      {
        title: 'Schizophrenia',
        total: '12',
      },
      {
        title: 'Asthma',
        total: '105',
      },
      {
        title: 'COPD',
        total: '18',
      },
      {
        title: 'Sickle Cell Anemia',
        total: '0',
      },
    ]

    const resolveStatisticsIconVariation = data => {
      if (data === 'Self Harm with Hospital') return { icon: mdiPoll, color: 'primary' }
      if (data === 'Self Harm without Hospital') return { icon: mdiPoll, color: 'success' }
      if (data === 'Single Celled') return { icon: mdiPoll, color: 'warning' }
      if (data === 'From Seg to General Pop') return { icon: mdiPoll, color: 'warning' }

      return { icon: mdiPoll, color: 'success' }
    }

    return {
      averagePopulation,
      detoxPopulation,
      suicideWatch,
      sickCalls,
      medErrorChart,
      totalMedVerifications,
      medicationRadialChart,
      psychotropicsRadialChart,
      alertsData,
      resolveStatisticsIconVariation,
      chronicClinicData,

      icons: {
        mdiPoll,
        mdiCalendar,
      },
    }
  },
  data: () => ({
    dates: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
    menu: false,
  }),
  computed: {
    filteredChronicClinic() {
      return this.chronicClinicData.filter(data => data.total >= '1')
    },
  },
}
</script>
